import React, { useState, useEffect } from 'react'
import { MultiSelect } from "react-multi-select-component"
import moment from 'moment'
import utils from '../../services/utils'
import constants, { SEARCH_PARAM_DEPARTMENT } from '../../constants'
import './ConversationsSearchCriteria.css'
import 'antd/dist/reset.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const ConversationsSearchCriteria = (props) => {
    const { orgs, queues, role, divisions , agents, conv_direction, current_agent_email} = props
    const [dnis, setDNIS] = useState()
    const [ani, setANI] = useState()
    const [conversationID, setConversationID] = useState();
    const [dynamicAgents,setDynamicAgents] = useState([]);

    const [selectedDivisions, setSelectedDivisions] = useState([])
    const [selectedOrg, setSelectedOrg] = useState([])
    
    const [selectedAgents, setSelectedAgents] = useState([])
    const [selectedConvDirection, setSelectedConvDirection] = useState([])
    const [selectedQueues, setSelectedQueues] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    function onSelectDate(dateArray, dateString) {
        console.log(`onSelectDate value is ${JSON.stringify(dateString)}`);
        setdateRange(dateString);
    }

    const ondivisionChangeActions = (choosenDivisions) =>{
        setSelectedDivisions(choosenDivisions);

        let agentsList = agents;
        console.log(`Choosen Divisions : ${JSON.stringify(choosenDivisions)}`);
        if(choosenDivisions.length) {
            let divisionsNameList = choosenDivisions.map(division => division.label);        
            agentsList = agents.filter(agent => divisionsNameList.includes(agent.divisionId[0]) || divisionsNameList.includes(agent.divisionId[1]));
    
        }
        console.log(`Short listed Agents, WRT Choosen Divisions : ${JSON.stringify(agentsList)}`)
        setDynamicAgents(agentsList);
    }

    // init from session storage
    useEffect(() => {
        try {
            // sessionStorage.setItem(constants.R2S_CONVERSATIONS,'');
            console.log(`ConversationsSearchCriteria:::: got search criteria as ${sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA)}`);
            const searchCriteriaTemp = JSON.parse(sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA));
            console.log(`ConversationsSearchCriteria:::: got search criteria as ${JSON.stringify(searchCriteriaTemp)}`);
            
            let agentsList = agents;
            if(searchCriteriaTemp){
                try {
                    if(searchCriteriaTemp.startDateTime && searchCriteriaTemp.endDateTime){
                        let tempStartDate = new Date(searchCriteriaTemp.startDateTime);
                        console.log(`Setting Start date time as ${JSON.stringify(searchCriteriaTemp.startDateTime)}`);
                        console.log(`Setting Start date time as ${tempStartDate}`);
                        console.log(`Setting Start date time as ${typeof tempStartDate}`);
                        let tempEndDate = new Date(searchCriteriaTemp.endDateTime);
                        console.log(`Setting Start date time as ${JSON.stringify(searchCriteriaTemp.endDateTime)}`);
                        console.log(`Setting Start date time as ${tempEndDate}`);
                        console.log(`Setting Start date time as ${typeof tempEndDate}`);
                        setdateRange(tempStartDate,tempEndDate);
                    }

                    if(searchCriteriaTemp.division) {
                        console.log(`Setting division as ${searchCriteriaTemp.division}`);
                        let tempCriteria ;
                        let selectedDivisionsTemp ;

                        if(searchCriteriaTemp.division == '*'){
                            selectedDivisionsTemp = divisions.filter((x => x.value!== '*'));
                        } else{
                            tempCriteria = searchCriteriaTemp.division.split(',');
                            selectedDivisionsTemp = divisions.filter((x => tempCriteria.includes(x.value)));

                            // shortlist agents based on divisions
                            let divisionsNameList = selectedDivisionsTemp.map(division => division.label);        
                            agentsList = agents.filter(agent => divisionsNameList.includes(agent.divisionId[0]) || divisionsNameList.includes(agent.divisionId[1]));
                        }
                        console.log(`Setting division as ${JSON.stringify(selectedDivisionsTemp)}`);
                        setSelectedDivisions(selectedDivisionsTemp);
                    }

                    if(searchCriteriaTemp.agent){
                        console.log(`Setting Agent as ${searchCriteriaTemp.agent.split(',')}`);
                        let tempCriteria ;
                        let selectedAgentTemp ;
                         if(searchCriteriaTemp.agent == '*'){
                            selectedAgentTemp = agentsList.filter((x => x.value!== '*'));
                        } else{
                            tempCriteria = searchCriteriaTemp.agent.split(',');
                            selectedAgentTemp = agentsList.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedAgentTemp)}`);
                        setSelectedAgents(selectedAgentTemp);
                        //setEndDate(searchCriteriaTemp.endDateTime);
                    }

                    if(searchCriteriaTemp.conv_direction){
                        console.log(`Setting conv_direction as ${searchCriteriaTemp.conv_direction}`);
                        if(searchCriteriaTemp.conv_direction == '*' || searchCriteriaTemp.conv_direction == ''){
                            setSelectedConvDirection(conv_direction.filter((x => x.value !== '*')));
                        }else{
                            setSelectedConvDirection(conv_direction.filter((x => x.value == searchCriteriaTemp.conv_direction)));
                        }
                    }

                    if(searchCriteriaTemp.queues){
                        console.log(`Setting queues as ${searchCriteriaTemp.queues}`);
                        let tempCriteria ;
                        let selectedQueuesTemp ;
                        if(searchCriteriaTemp.queues == '*'){
                            selectedQueuesTemp = queues.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.queues.split(',');
                            selectedQueuesTemp = queues.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting queues as ${JSON.stringify(selectedQueuesTemp)}`);
                        setSelectedQueues(selectedQueuesTemp);
                    }

                    if(searchCriteriaTemp.org){
                        console.log(`Setting Org as ${searchCriteriaTemp.org}`);
                        let tempCriteria ;
                        let selectedOrgTemp ;
                        if(searchCriteriaTemp.org == '*'){
                            selectedOrgTemp = orgs.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.org.split(',');
                            selectedOrgTemp = orgs.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Org as ${JSON.stringify(selectedOrgTemp)}`);
                        setSelectedOrg(selectedOrgTemp);
                    }


                    if(searchCriteriaTemp.ani){
                        setANI(searchCriteriaTemp.ani);
                    }

                    if(searchCriteriaTemp.dnis){
                        setDNIS(searchCriteriaTemp.dnis);
                    }
                    
                    if(searchCriteriaTemp.conversationID){
                        setConversationID(searchCriteriaTemp.conversationID);
                    }

                    console.log(`Set all Search Criteria from previous search `);
                } catch (error) {
                     console.log(error);
                }
            }

            setDynamicAgents(agentsList);

            if (!searchCriteriaTemp) return;

        } catch (error) {
            console.log('conversationsSearchCriteria.useeffect.error', error)
        }
    }, [])

    const search = async () => {
        setStartSearch(true)
        if (!dateRange[0] || !dateRange[1]) return
        console.log('Searching...')
        const searchCriteria = new Map()
        if (dateRange[0]){
            let tempStartDate = moment(dateRange[0],'YYYY-MM-DD').set({"hour": 0, "minute": 0});           
            searchCriteria.set(constants.SEARCH_PARAM_STARTDT, tempStartDate.toISOString());
        } 

        if (dateRange[1]){
            let tempEndDate = moment(dateRange[1],'YYYY-MM-DD').set({"hour": 23, "minute": 59});
            searchCriteria.set(constants.SEARCH_PARAM_ENDDT,tempEndDate.toISOString() );
        } 
    
        if(selectedDivisions){
            console.log(`Selected Divisions are ${JSON.stringify(selectedDivisions)}`)
            //searchCriteria.set('selectedDivisions', selectedDivisions)
            console.log(`Role is ${role}`);
            if (selectedDivisions.length === divisions.length || selectedDivisions.length === 0 ){ // all
                console.log('All Divisions Selected');
                if(role =='admin'){
                    searchCriteria.set(constants.SEARCH_PARAM_DIVISION, '*')
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_DIVISION, decodeURIComponent(divisions.map(x => x.value)));
                }
            }else{
                searchCriteria.set(constants.SEARCH_PARAM_DIVISION, decodeURIComponent(selectedDivisions.map(x => x.value)));
            }
        }
        if (selectedQueues) {
            //searchCriteria.set('selectedQueues', selectedQueues)
            if (selectedQueues.length === queues.length || selectedQueues.length === 0){ // all
                if(role =='admin'){
                    searchCriteria.set(constants.SEARCH_PARAM_QUEUES, '*')
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_QUEUES, decodeURIComponent(queues.map(x => x.value)));
                }
            }else{
                searchCriteria.set(constants.SEARCH_PARAM_QUEUES, decodeURIComponent(selectedQueues.map(x => x.value)));
            }
        }

        if (selectedOrg) {
            //searchCriteria.set('selectedQueues', selectedQueues)
            if (selectedOrg.length === orgs.length || orgs.length === 0){ // all
                if(role =='admin'){
                    searchCriteria.set(constants.SEARCH_PARAM_ORG, '*')
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_ORG, decodeURIComponent(orgs.map(x => x.value)));
                }
            }else{
                searchCriteria.set(constants.SEARCH_PARAM_ORG, decodeURIComponent(selectedOrg.map(x => x.value)));
            }
        }
        

        if (selectedAgents) {
            //searchCriteria.set('selectedAgents', selectedAgents);
            if (selectedAgents.length === agents.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, decodeURIComponent(selectedAgents.map(x => x.value)));
        }

        if(role === 'client_user' && current_agent_email) {
            console.log(`Processing Detail of a non admin: ${current_agent_email}`);
            let selectedAgentTemp = agents.filter((x => x.label === current_agent_email));
            searchCriteria.set(constants.SEARCH_PARAM_AGENT, decodeURIComponent(selectedAgentTemp.map(x => x.value)));
        }

        if (selectedConvDirection) {
            //searchCriteria.set('selectedAgents', selectedAgents);
            if (selectedConvDirection.length === conv_direction.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, decodeURIComponent(selectedConvDirection.map(x => x.value)));
        }
        if (ani) searchCriteria.set(constants.SEARCH_PARAM_ANI, decodeURIComponent(ani));
        if (dnis) searchCriteria.set(constants.SEARCH_PARAM_DNIS, decodeURIComponent(dnis));
        if (conversationID) searchCriteria.set(constants.SEARCH_PARAM_CONVERSATIONID, conversationID);

        console.log(`Search Criteria after processing is ${JSON.stringify(utils.mapToObj(searchCriteria))}`);
        sessionStorage.setItem(constants.R2S_SEARCH_CRITERIA, JSON.stringify(utils.mapToObj(searchCriteria)));
        console.log(`Setting Conversation Search Criteria as ${JSON.stringify(sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA))}`);

        props.search(utils.mapToObj(searchCriteria))
        
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }

    return (
        <div className="search-wrap pl-2 pb-2">
            <div className='row justify-content-start'>

                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Genesys Cloud Org</span>
                        </div>
                        <MultiSelect className="multi-select agents-multi-select"
                            options={orgs}
                            value={selectedOrg}
                            onChange={e => { setSelectedOrg(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>

                <div className='col col-12 col-xl-3' >
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Division</span>
                        </div>
                        <MultiSelect className="multi-select division-multi-select"
                            options={divisions}
                            value={selectedDivisions}
                            onChange={e => {ondivisionChangeActions(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Queues</span>
                        </div>
                        <MultiSelect className="multi-select queues-multi-select"
                            options={queues}
                            value={selectedQueues}
                            onChange={e => { setSelectedQueues(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>
                

                
            </div>
            <div className='row justify-content-start'>


            </div>

            
            <div className='row justify-content-start'>

                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">ANI</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={ani}
                            onChange={e => setANI(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">DNIS</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={dnis}
                            onChange={e => setDNIS(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Conversation ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={conversationID}
                            onChange={e => setConversationID(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-start'>
                    <div className='col col-12 col-xl-3'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Direction of the Call</span>
                            </div>
                            <MultiSelect className="multi-select direction-multi-select"
                                options={conv_direction}
                                value={selectedConvDirection}
                                onChange={e => { setSelectedConvDirection(e) }}
                                labelledBy="Select"
                            />

                        </div>
                    </div>
                {(role === 'admin' || role === 'internal_user') ? <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Agent Name</span>
                        </div>
                        <MultiSelect className="multi-select agents-multi-select"
                            options={dynamicAgents}
                            value={selectedAgents}
                            onChange={e => { setSelectedAgents(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div> : ""}
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Interval *</span>
                        </div>

                        <RangePicker size="large" aria-required placement="bottomLeft" style={{ borderRadius: "5px" }} onChange={onSelectDate} />
                    </div>
                </div>
                
            </div>
            <br />
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={search}>Search</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>


    )


}

export default ConversationsSearchCriteria;